import React, { useEffect, useRef } from 'react'
import { useIsMobile } from 'utils'

const { jarallax } = typeof window !== "undefined" ? require("jarallax") : () => null

export default ({ children, className, backgroundUrl = null }) => {
    let hasInitialisedJarallax = false

    const isMobile = useIsMobile()

    const ref = useRef(null)
    const isMobileRef = useRef(isMobile)

    isMobileRef.current = isMobile

    useEffect(() => {
        if (backgroundUrl && ref && ref.current && !hasInitialisedJarallax) {
            hasInitialisedJarallax = true

            setTimeout(() => {
                if (!isMobileRef.current) {
                    jarallax(ref.current, {
                        speed: 0.6
                    })
                }
            }, 10)
        }
    }, [backgroundUrl])

    return (
        <section className={`jarallax ${className || ''}`}
            style={{
                backgroundImage: backgroundUrl ? `url(${backgroundUrl})` : null
            }}
            ref={ref}
        >
            {children}
        </section>
    )
}